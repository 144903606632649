/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import 'rsuite/Placeholder/styles/index.css';

// reactstrap components
import { Card, CardBody, CardTitle, Col, Container, Nav, Row } from "reactstrap";
import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";

import { Placeholder } from 'rsuite';
import ProtectedRoute from 'auth/protectedRoute';

const Header = (props) => {

  const [processInfo, setProcessInfo] = useState({});
  const [isLostConnection, setIsLostConnection] = useState(true);

  React.useEffect(() => {
    setProcessInfo(props.processesInfo);
    setIsLostConnection(props.lostConnection);
  }, [props.processesInfo]);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                      <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Nº de processos
                        </CardTitle>
                        <span className="div font-weight-bold mb-0">
                          {processInfo.number_process == undefined ? <Placeholder.Paragraph rows={1} /> : processInfo.number_process}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i className="fas fa-file" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Sem classificação
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{
                        processInfo.number_process_without_classification == undefined ? <Placeholder.Paragraph rows={1} /> : processInfo.number_process_without_classification
                      }</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i className="fas fa-file-circle-exclamation" />
                        </div>
                      </Col>
                    </Row>
                    {/* <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Desde o último mês</span>
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
              <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Submetidos hoje
                          </CardTitle>
                          <span className="div font-weight-bold mb-0">{
                          processInfo.delivery_today_to_user == undefined ? <Placeholder.Paragraph rows={1} /> :
                          processInfo.delivery_today_to_user
                        }</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-calendar-day" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Desde ontem</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
              <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className='card-stats mb-4 mb-xl-0'>
                    <CardBody>
                      <Row>
                        <div className='col'>
                          <CardTitle
                            tag='h5'
                            className='text-uppercase text-muted mb-0'
                          >
                            Último criado
                          </CardTitle>
                          <span className='div font-weight-bold mb-0'>{
                          processInfo?.last_process_created == undefined ? <Placeholder.Paragraph rows={1} /> :
                          <NavLink to={`/gerenciar/processo/${processInfo.last_process_created}`} className='text-primary'>
                            {processInfo?.last_process_created}
                          </NavLink>
                          }</span>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-info text-white rounded-circle shadow'>
                            <i className='fas fa-user-tag' />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
              <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Classificados hoje
                          </CardTitle>
                          <span className="div font-weight-bold mb-0">{
                          processInfo.classified_today_by_user == undefined ? <Placeholder.Paragraph rows={1} /> :
                            processInfo.classified_today_by_user
                          }</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-user-tag" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Da média</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
              <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Maior classificador
                          </CardTitle>
                          <span className="div font-weight-bold mb-0">{
                            isLostConnection ? <Placeholder.Paragraph rows={1} /> : 
                              (
                                processInfo.most_user_id == null ? <Placeholder.Paragraph rows={1} /> :
                              <NavLink to={`/painel-de-controle/usuarios/${processInfo.most_user_id}`} className="text-primary">
                                {processInfo.most_user_first_name + ' ' + processInfo.most_user_last_name}
                              </NavLink>)
                          }</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-user-tag" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Da média</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </ProtectedRoute>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;