// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 250px;
    height: 600px;
    max-width: 250px;
    transition: max-width 0.3s ease-in-out;
}

.sidebar-closed {
    max-width: 0;
    overflow: hidden;
    transition: max-width 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/views/processes/uniqprocess.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,sCAAsC;AAC1C;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,sCAAsC;AAC1C","sourcesContent":[".sidebar {\n    width: 250px;\n    height: 600px;\n    max-width: 250px;\n    transition: max-width 0.3s ease-in-out;\n}\n\n.sidebar-closed {\n    max-width: 0;\n    overflow: hidden;\n    transition: max-width 0.3s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
