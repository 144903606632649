import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const REQUESTS_URL = process.env.REACT_APP_ENDPOINT_REQUESTS;
const BASEURL = process.env.REACT_APP_BASE_URL;
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export async function requestChangeProcessCategory(processId, categoryId, userId, justf) {

    const transformedJson = JSON.stringify({
        "process_id": processId,
        "category_id": categoryId,
        "classifier_user_id": userId,
        "justify": justf,
    });

    try {
        const response = await axios.post(BASEURL + REQUESTS_URL, transformedJson, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
            }
        });
        
        if (response.status === 201) {
            return response;
        }
    } catch (error) {
        console.log(error)
        return null;
    }
}

export async function getRequests(processId, categoryId, userId, status, type, seen) {
    try {
        const response = await axios.get(BASEURL + REQUESTS_URL + '?process='+processId+'&category='+categoryId
            +'&classifier_user='+userId+ '&status='+ status + '&type='+type + "&seen="+seen, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
            }
        });
        if (response.status === 200) {
            return response;
        }
    }
    catch (error) {
        console.log(error)
        return null;
    }
}

export async function deleteRequestById(requestId) {
    try {
        const response = await axios.delete(BASEURL + REQUESTS_URL + requestId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
            }
        })
        if (response.status === 204) {
            return response;
        }
    }
    catch (error) {
        return null;
    }
} 

export async function setStatusRequest(requestId, status, comment) {
    const transformedJson = JSON.stringify({
        "status": status,
        "comment": comment,
    });
    try {
        const response = await axios.put(BASEURL + REQUESTS_URL + requestId + '/', transformedJson, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
            }
        })
        if (response.status === 200) {
            return response;
        }
    }
    catch (error) {
        return null;
    }
}

export async function approveRequest(requestId, comment) {

    const transformedJson = JSON.stringify({
        "change_request_id": requestId,
        "comment": comment,
    });

    try {
        const response = await axios.post(BASEURL + REQUESTS_URL  + 'execute/', transformedJson, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
            }
        })
        if (response.status === 200) {
            return response;
        }
    }
    catch (error) {
        return null;
    }
}

export async function setSeenRequest(requestId, boolseen) {

    const transformedJson = JSON.stringify({
        "seen": boolseen,
    });

    try {
        const response = await axios.put(BASEURL + REQUESTS_URL + requestId + '/', transformedJson, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getTokenDecript(),
                'Application-Token': TOKEN,
            }
        })
        if (response.status === 200) {
            return response;
        }
    }
    catch (error) {
        return null;
    }
}
