/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Table
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";

import { getInfosDecript, getAdditionalInfoDescript } from "auth/jwtUtils";

import { getCategoryList } from "services/categoryService";

import React, {useEffect, useState} from "react";

import { Placeholder, Tooltip, Whisper } from 'rsuite';

import { Modal, Button, Input as InputRs, useToaster } from 'rsuite';

import Select from 'rsuite/SelectPicker'
import { MessageBox, messagesContent } from "../../components/Utils/MessageBox";
import { requestChangeProcessCategory, deleteRequestById, getRequests } from "services/requestService";

const alertFields = MessageBox("warning", "Atenção", messagesContent.common.alertFields)

const Profile = () => {

  const [categoryName, setCategoryName] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [modalReport, setModalReport] = useState(false);
  const handleModalReportClose = () => setModalReport(false);
  const handleModalReportOpen = () => setModalReport(true);

  // repetição de código 
  const STATUS = {
    'pendente': 'orange',
    'aprovado': 'green',
    'rejeitado': 'red',
  };

  const [reports, setReports] = useState([]);
  const [modalCancelReport, setModalCancelReport] = useState(false);
  const handleModalClose = () => setModalCancelReport(false);
  const [atualRequestId, setAtualRequestId] = useState();

  // report error
  
  const [reportFieldsFill, setReportFieldsFill] = useState([false, false]);

  const [justify, setJustify] = React.useState('');
  const [newCategory, setNewCategory] = React.useState();

  // toaster
  const toaster = useToaster();

  useEffect(() => {
    const fetchCategory = async () => {
      const response = await getCategoryList();
      return response;
    }

    const response = fetchCategory().then((res) => {
      const category = res.data?.results.filter((item) => item.id === getAdditionalInfoDescript().category);
      if(category.length > 0)
        setCategoryName(category[0].name);
      else
        setCategoryName('Nenhum núcleo atribuído');
    });

    const categoryList = fetchCategory().then((res) => {
      const category = res.data?.results.map((item) => ({ label: item.name, value: item.id }));
      setCategoryList(category);
    });
  },[]);

  // envia a solicitação de mudança de núcleo
  const handleReport = () => {
    if(!reportFieldsFill.includes(false)){
        const fetch = async () => {
            try {
            const resultado = await requestChangeProcessCategory(null, newCategory, getAdditionalInfoDescript().id, justify);
            if(resultado.status != 201){
                toaster.push(MessageBox("error", "Erro", "Ocorreu um erro em sua solicitação"), {duration: 5000 })
            }else{
                toaster.push(MessageBox("success", "Sucesso", "Solicitação enviada"), {duration: 5000 })
                setModalReport(false);
            }
            } catch (error) {
                console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        
        const fetchVerify = async () => {
          try {
              const resultado = await getRequests("", "", getAdditionalInfoDescript().id, "PENDENTE", 'user');
              if(resultado.status == 200){
                  if(resultado.data.results.length != 0){
                      toaster.push(MessageBox("warning", "Atenção", "Não foi possível prosseguir, há uma solicitação pendente"), {duration: 5000 })
                      setModalReport(false);
                      return;
                  }else{
                      fetch();
                  }
              }
          } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
          }
      }
      fetchVerify();
    }else{
        toaster.push(alertFields, {duration: 5000 })
    }
  }

  // verifica se o usuário ja fez uma solicitacao
  useEffect(() => {
    const fetch = async () => {
        try {
          const resultado = await getRequests("", "", getAdditionalInfoDescript().id, "PENDENTE", 'user');
          if(resultado.status == 200){
            setReports(resultado.data.results);
          }
        } catch (error) {
            console.error('Erro ao buscar dados assíncronos:', error);
        }
    }
    fetch();
  },[modalReport, modalCancelReport]);

  // validadores de campos
  useEffect(() => {
    try{

        if(justify == '' || justify == null || justify == undefined){
            document.getElementById('input-justify').style.borderColor = 'red';
            setReportFieldsFill(() => {reportFieldsFill[0] = false; return reportFieldsFill;});
        }else{
            document.getElementById('input-justify').style.borderColor = 'green';
            setReportFieldsFill(() => {reportFieldsFill[0] = true; return reportFieldsFill;});
        }

        if(newCategory == '' || newCategory == null || newCategory == undefined){
            document.getElementById('select-newCategory').style.borderColor = 'red';
            setReportFieldsFill(() => {reportFieldsFill[1] = false; return reportFieldsFill;});
        }else{
            document.getElementById('select-newCategory').style.borderColor = 'green';
            setReportFieldsFill(() => {reportFieldsFill[1] = true; return reportFieldsFill;});
        }
    }catch(error){
        
    }
  }, [justify, newCategory,modalReport])

  // cancela a solicitação
  const handleCancelRequest = () => {
    const fetch = async () => {
        try {
            const resultado = await deleteRequestById(atualRequestId);
            if(resultado.status != 204){
                toaster.push(MessageBox("error", "Erro", "Ocorreu um erro em sua solicitação"), {duration: 5000 })
            }else{
                toaster.push(MessageBox("success", "Sucesso", "Solicitação cancelada"), {duration: 5000 })
                setModalCancelReport(false);
            }
        } catch (error) {
            console.error('Erro ao buscar dados assíncronos:', error);
        }
    }
    fetch();
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Minha conta</h3>
                  </Col>
                  {/* <Col className="text-right">
                    <Button
                      style={{ backgroundColor: '#fb923c', color: 'white' }}
                      onClick={() => {handleModalReportOpen();
                        setJustify('');
                        setNewCategory('');
                        setReportFieldsFill([false, false]);
                      }}
                      size="sm"
                    >
                      
                       Solicitar mudança de núcleo
                    </Button>
                  </Col> */}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do usuário
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Usuário
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getInfosDecript().username}
                            id="input-username"
                            placeholder="Username"
                            type="text"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            defaultValue={getInfosDecript().sub}
                            type="email"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Primeiro nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getAdditionalInfoDescript().firstName}
                            id="input-first-name"
                            disabled={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Último nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            defaultValue={getAdditionalInfoDescript().lastName}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Informações no sistema
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                    <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Núcleo
                          </label>
                          <div style={{ display: 'flex' }}>
                          <Input
                            className="form-control-alternative"
                            defaultValue={categoryName}
                            id="input-nucleo"
                            type="text"
                            width="80%"
                            disabled={true}
                          />
                          
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {/* <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Solicitações
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                    <Table responsive>
                      <thead>
                          <tr>
                              <th>Tipo</th>
                              <th>Novo núcleo</th>
                              <th>Justificativa</th>
                              <th>Comentário</th>
                              <th>Status</th>
                              <th>Ações</th>
                          </tr>
                      </thead>
                      <tbody>
                          { reports.length == 0 ? <tr><td colSpan="6" className="text-center">Nenhuma solicitação</td></tr> :
                              reports.map((item) => {
                                  return (
                                      <tr>
                                          <td>Núcleo incorreto</td>
                                          <td>{item.category.name}</td>
                                          <td>{item.justify}</td>
                                          <td>{item.comment}</td>
                                          <td><i class="fa-solid fa-circle fa-sm mr-2" style={{ color: 
                                                              STATUS[item.status.toLowerCase()]
                                                          }}></i>{item.status}</td>
                                          <td>
                                             <Whisper placement="top" trigger="hover" speaker={
                                              <Tooltip>
                                                  Cancelar solicitação
                                              </Tooltip>
                                             }>
                                              <Button style={{ backgroundColor: '#F87171', color: 'white' }} className='mr-2' size="sm" onClick={() =>{
                                                  setModalCancelReport(true)
                                                  setAtualRequestId(item.id)
                                              }}>
                                                  <i className="fa-solid fa-xmark"></i>
                                              </Button>
                                              </Whisper>
                                          </td>
                                      </tr>
                                  )
                              })
                          }
                      </tbody>
                  </Table>
                    </Row>
                  </div> */}
                  <hr className="my-4" />
                  {/* Description */}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal open={modalReport} role="alertdialog" onClose={handleModalReportClose} backdrop={'static'}>
          <Modal.Header>
          <Modal.Title>Solicitar mudança de núcleo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Label>Nucleo destino</Label>
                  <Select style={{ width: 224 }}  id="select-newCategory"  placeholder="Selecione o núcleo" data={categoryList.filter((item) => {
                    return item.value !== getAdditionalInfoDescript().category;
                  })} value={newCategory} onChange={setNewCategory} size="sm"></Select>
              </div>

              <div style={{ marginTop: 20 }}>
                  <Label>Justificativa</Label>
                  <InputRs as="textarea" rows={4} id="input-justify" placeholder="Descreva o motivo" value={justify} onChange={setJustify}></InputRs>
              </div>
          </Modal.Body>
          <Modal.Footer>
          <Button size="sm" onClick={handleModalReportClose} appearance="subtle">
              Cancelar
          </Button>
          <Button size="sm" color="primary" onClick={handleReport} appearance="primary">
              Confirmar
          </Button>
          </Modal.Footer>
      </Modal>

      <Modal open={modalCancelReport} onClose={handleModalClose}>
          <Modal.Header>
          <Modal.Title>Cancelar solicitação</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              Deseja realmente cancelar a solicitação? Essa ação é irreversível.
          </Modal.Body>
          <Modal.Footer>
          <Button size="sm" onClick={() => setModalCancelReport(false)} appearance="subtle">
              Cancelar
          </Button>
          <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleCancelRequest} appearance="primary">
              Confirmar
          </Button>
          </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
