import '../../assets/css/rsuite.css';

//table
import {
    Badge,
    Card,
    CardFooter,
    CardHeader,
    Container,
    Label,
    Media,
    Row,
    Table,
} from "reactstrap";
import { CustomLoadingOverlay, CustomNoRowsOverlay, LostConnectionOverlay } from '../../components/Utils/nodata.js'
import { Input, Message, Modal, useToaster } from "rsuite";
//header
import React, { useEffect, useState } from "react";
import { deleteSubsubject, getSubSubjectList, getSubsubjectParams, saveSubsubject, updateSubsubject } from "services/subsubjectService.js"

import Drawer from 'rsuite/Drawer';
import ProtectedRoute from "auth/protectedRoute.js";
import Select from 'rsuite/SelectPicker'
import { getSubjectList } from "services/subjectService.js";

import { Pagination, Button, } from 'rsuite';

import { MessageBox, messagesContent } from "../../components/Utils/MessageBox"

const messageError = MessageBox("error", "Erro", messagesContent.subsubject.messageError)
const messageUpdateError = MessageBox("error", "Erro", messagesContent.subsubject.messageUpdateError)
const messageUpdateSuccess = MessageBox("success", "Sucesso", messagesContent.subsubject.messageUpdateSuccess)
const errorDeleteAlert = MessageBox("error", "Erro", messagesContent.subsubject.errorDeleteAlert)
const successDeleteAlert = MessageBox("success", "Sucesso", messagesContent.subsubject.successDeleteAlert)
const messageSuccess = MessageBox("success", "Sucesso", messagesContent.subsubject.messageSuccess)
const alertFields = MessageBox("warning", "Atenção", messagesContent.common.alertFields)

export default function Subsubject(){

    const toaster = useToaster();

    const [subsubject, setSubsubject] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [lostConnection, setLostConnection] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [subject, setSubject] = useState('')

    const [nomeAssuntoSearch, setnomeAssuntoSearch] = useState('')
    const [descriptionAssuntoSearch, setDescriptionAssuntoSearch] = useState('')
    const [subjectSearch, setSubjectSearch] = useState('')

    const [subjectList, setSubjectList] = useState([]);

    const [allFieldsFill, setAllFieldsFill] = useState([false, false, false]);

    const [atualItem, setAtualItem] = useState();

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);  
    const handleModalClose = () => setModalOpen(false);

    //pagination states
    const limitOptions = [25, 50, 100];
    const [layout, setLayout] = React.useState(['total', '-', 'limit', '|', 'pager', 'skip']);
    const [total, setTotal] = React.useState(1);
    const [activePage, setActivePage] = React.useState(1);
    const [limit, setLimit] = React.useState(25);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === "1") {
                event.preventDefault();
                setOpen(true);
            }else if(event.ctrlKey && event.key === "2"){
                event.preventDefault();
                //DONOTHING 
            }else if(event.ctrlKey && event.key === "3"){
                event.preventDefault();
                if(open){
                    handleSubmit();
                }else if(openEdit){
                    handleUpdate();
                }
            }else if(event.ctrlKey && event.key === "4"){
                event.preventDefault();
                setOpenEdit(false);
                setOpen(false); 
            }else if(event.ctrlKey && event.key === "0"){
                event.preventDefault();
                handleClear();
            }else if(event.ctrlKey && event.key === "7"){
                event.preventDefault();
                handleClearSearch();
            }else if(event.ctrlKey && event.key === "8"){
                event.preventDefault();
                searchParams();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    },);
    
    //busca lista de assunto
    useEffect(() => {
        const fetchData = async () => {
          try {
            const resultado = await getSubjectList();
            if(resultado.status != 200){
                setSubjectList([]);
            }else{
                setSubjectList(
                    resultado.data.results.map((assunto) => {
                        return {label: assunto.name, value: assunto.id}
                    })
                );
            }
          } catch (error) {
            console.error('Erro ao buscar dados assíncronos:', error);
          }
        };
        fetchData();

    },[]); 

    //busca lista de subassunto
    useEffect(() => {
        const fetchData = async () => {
          try {
            const resultado = await getSubSubjectList(activePage, limit, true);
            if(resultado.status != 200){
                setSubsubject([]);
                setIsLoading(false);
                setLostConnection(true);
            }else{
                setSubsubject(resultado.data.results);
                setTotal(resultado.data.count)
                setIsLoading(false);
            }
          } catch (error) {
            console.error('Erro ao buscar dados assíncronos:', error);
          }
        };
        fetchData();

    },[]); 
                


    //validator
    useEffect(() => {
        try{
            if(name != '' && name != null && name != undefined){
                document.getElementById('input-name').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[0] = true; return allFieldsFill;});
            }else{
                document.getElementById('input-name').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[0] = false; return allFieldsFill;});
            }

            if(subject != '' && subject != null && subject != undefined){
                document.getElementById('select-assunto').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[1] = true; return allFieldsFill;});
            }else{
                document.getElementById('select-assunto').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[1] = false; return allFieldsFill;});
            }

            if(description != '' && description != null && description != undefined){
                document.getElementById('input-description').style.borderColor = 'green';
                setAllFieldsFill(() => {allFieldsFill[2] = true; return allFieldsFill;});
            }else{
                document.getElementById('input-description').style.borderColor = 'red';
                setAllFieldsFill(() => {allFieldsFill[2] = false; return allFieldsFill;});
            }

        }catch(error){
            
        }


    }, [name, subject, description])


    //envia assunto
    const handleSubmit = () => {

        if(allFieldsFill.includes(false)){
            toaster.push(alertFields, {duration: 5000 })
            return;
        }
        
        const sub = {name: name, subject_id: subject, description: description}
        const saveData = async () => {
            try {
              const resultado = await saveSubsubject(sub);
              if(resultado.status != 201){
                toaster.push(messageError, {duration: 5000 })
              }else{
                toaster.push(messageSuccess, {duration: 5000 })
                setSubsubject([resultado.data, ...subsubject]);
                setOpen(false);
                handleClear();
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

        saveData();
    }


    //deleta processo
    const handleDelete = () => {

        const handleDelete = async () => {
            try {
              const resultado = await deleteSubsubject(atualItem);
              if(resultado.status != 204){
                toaster.push(errorDeleteAlert, {duration: 5000 })
              }else{
                toaster.push(successDeleteAlert, {duration: 5000 })
                setSubsubject(subsubject.filter(sub => sub.id !== atualItem))
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

          handleDelete();
          handleModalClose();
    }	

    //limpa campos
    function handleClear() {
        
        setName('');
        setSubject('');
        setDescription('');
        setAllFieldsFill([false, false, false]);
    }

    //limpa campos do search
    function handleClearSearch() {
        setnomeAssuntoSearch('');
        setDescriptionAssuntoSearch('')
        setSubjectSearch('')
    }

    // fecha o modal
    const handleEditModalClose = () => {
        
        setOpenEdit(false);
        handleClear();
    }

    //abre modal de edição
    const handleClickEdit = (assuntoname, subject, descriptionname) => {
        setName(assuntoname)
        if(subject != null){
            setSubject(subject.id)
        }
        setDescription(descriptionname)
        setOpenEdit(true);
    }

    //atualiza dados
    const handleUpdate = () => {
        const subUpdate = { name: name, subject_id: subject, description: description}
        const saveData = async () => {
            try {
              const resultado = await updateSubsubject(atualItem, subUpdate);
              if(resultado.status != 200){
                toaster.push(messageUpdateError, {duration: 5000 })
              }else{
                toaster.push(messageUpdateSuccess, {duration: 5000 })
                setSubsubject(subsubject.map(sub => sub.id === atualItem ? resultado.data : sub))
                setOpenEdit(false);
                handleClear();
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
          };

          saveData();
        
    }

    //busca com parametros
    const searchParams = () => {
        setIsLoading(true);
        const fetchData = async () => {
            try {
              const resultado = await getSubsubjectParams({name: nomeAssuntoSearch, subject: subjectSearch, description: descriptionAssuntoSearch}, activePage, limit, true);
              if(resultado.status != 200){
                setSubsubject([]);
                setIsLoading(false);
              }else{
                setSubsubject(resultado.data.results);
                setTotal(resultado.data.count)
                setIsLoading(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        }
        fetchData();
    }

    // alteracao na paginacao
    useEffect(() => {
        const fetchData = async () => {
            try {
              const resultado = await getSubsubjectParams({name: nomeAssuntoSearch, subject: subjectSearch, description: descriptionAssuntoSearch}, activePage, limit, true);
              if(resultado.status != 200){
                setSubsubject([]);
                setIsLoading(false);
              }else{
                setSubsubject(resultado.data.results);
                setTotal(resultado.data.count)
                setIsLoading(false);
              }
            } catch (error) {
              console.error('Erro ao buscar dados assíncronos:', error);
            }
        };

        fetchData();
    },[activePage, limit])

    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                <div className="header-body">
                </div>
                <div className="bg-white p-4 rounded">
                    <h3 className="mb-4 mt-2 ml-1">Subassunto</h3>
                    <div className="row">
                        <div className="col-3">
                            <Label>Nome</Label><Input placeholder="Digite o nome do assunto" value={nomeAssuntoSearch} onChange={setnomeAssuntoSearch}></Input>
                        </div>
                        <div className="col-8">
                            <Label>Descrição</Label><Input placeholder="Digite a descrição" value={descriptionAssuntoSearch} onChange={setDescriptionAssuntoSearch}></Input>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap gap-3">
                        <div className="flex-grow-1 mt-3">
                            <Label style={{ width: '100%' }}>Assunto</Label>
                            <Select value={subjectSearch} onChange={setSubjectSearch} size="md" style={{ width: '350px' }} placeholder="selecione o assunto" data={subjectList} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <Button className='mr-3' color="warning" size="md" onClick={handleClearSearch}><i class="fa-solid fa-trash mr-2"></i>Limpar</Button>
                        <Button color="primary" size="md" onClick={searchParams}><i class="fa-solid fa-magnifying-glass mr-2" ></i>Pesquisar</Button>
                    </div>
                </div>
                </Container>
                
            </div>
            
            <Container className="mt--7" style={{ minHeight: '70vh' }} fluid>
        {/* Table */}
            <Row>
                <div className="col">
                    <Card className="shadow">
                    <CardHeader className="border-0 d-flex flex-row align-items-center justify-content-between">
                        <h3 className="mb-0">Subassunto</h3>
                        <ProtectedRoute  permissionsAccepted={['add_subsubject']} mode={'component'}>
                            <div>
                                <Button color="primary" size="md" onClick={() => setOpen(true)} ><i className="fas fa-plus mr-2 "  />Novo Subassunto</Button>
                            </div>
                        </ProtectedRoute>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                        <tr>
                            <th scope="col">Nome </th>
                            <th scope="col">Assunto</th>
                            <th scope="col">Descrição</th>
                            <ProtectedRoute permissionsAccepted={['change_subsubject', 'delete_subsubject']} mode={'component'}>
                                <th scope="col">Ações </th>
                            </ProtectedRoute>
                        </tr>
                        </thead>
                        <tbody>
                            {
                            !isLoading && !lostConnection && subsubject.length > 0 &&
                                subsubject.map((sub) => {
                                    
                                    return (<tr key={sub.id}>
                                        <th scope="row">
                                            <Media>
                                                <span className="mb-0 text-sm">
                                                    {sub.name}
                                                </span>
                                            </Media>
                                        </th>
                                        <th>
                                            {
                                                sub.subject == null ? "" : sub.subject.name
                                            }
                                        </th>
                                        <th>
                                            <div className="d-flex align-items-center">
                                                <span className="mr-2" style={{ whiteSpace:'normal', wordWrap: 'break-word' }}>
                                                    {
                                                        sub.description == null ? "" : sub.description
                                                    }
                                                </span>
                                            </div>
                                        </th>
                                        <ProtectedRoute permissionsAccepted={['change_subsubject', 'delete_subsubject']} mode={'component'}>
                                            <td className="align-right">
                                                <ProtectedRoute permissionsAccepted={['change_subsubject']} mode={'component'}>
                                                    <Button className='mr-2' color="info" onClick={() => {
                                                        setAtualItem(sub.id);
                                                        handleClickEdit(sub.name, sub.subject, sub.description)}}>
                                                        <i class="fa-solid fa-pen"></i>
                                                    </Button>
                                                </ProtectedRoute>
                                                <ProtectedRoute permissionsAccepted={['delete_subsubject']} mode={'component'}>
                                                    <Button style={{ backgroundColor: "#f87171", color: 'white' }} onClick={() => {
                                                                setAtualItem(sub.id);
                                                                setModalOpen(true);}}>
                                                        <i class="fa-solid fa-trash"></i>
                                                    </Button>
                                                </ProtectedRoute>
                                            </td>
                                        </ProtectedRoute>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>
                    
                    {isLoading &&<div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomLoadingOverlay()}
                    </div>}
                    {!isLoading && lostConnection &&
                        <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                            { LostConnectionOverlay()}
                        </div>
                    }
                    {!isLoading && !lostConnection && subsubject.length == 0 &&
                        <div className="mb-4 mt-4 w-100 d-flex align-items-center justify-content-center flex-column" >
                        {CustomNoRowsOverlay()}
                         </div>
                    }
                    <CardFooter className="py-4">
                        <nav aria-label="...">
                        <Pagination
                                layout={layout}
                                size={'xs'}
                                prev={true}
                                next={true}
                                first={true}
                                last={true}
                                ellipsis={true}
                                boundaryLinks={true}
                                total={total}
                                limit={limit}
                                limitOptions={limitOptions}
                                maxButtons={4}
                                activePage={activePage}
                                onChangePage={setActivePage}
                                onChangeLimit={setLimit}
                            />
                        </nav>
                    </CardFooter>
                    </Card>
                </div>
            </Row>
            {/* adicionar subassunto */}
            <ProtectedRoute  permissionsAccepted={['add_subsubject']} mode={'component'}>
                <Drawer backdrop="static" open={open} onClose={() => setOpen(false)}
                    size={(window.innerWidth < 768) ? 'full' : 'sm'}
                    >
                    <Drawer.Header className="pt-4">
                        <h4>Cadastro de Subassunto</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Nome</h4>
                                <Input placeholder="Digite o nome do núcleo" id="input-name" value={name} onChange={setName}></Input>
                            </Row>
                            <Row className="mb-4">
                                <h4>Assunto</h4>
                                <Select id="select-assunto" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Descrição</h4>
                                <Input placeholder="Digite uma observação" as="textarea" rows={10} id="input-description" value={description} onChange={setDescription}></Input>
                            </Row>
                            <Row>
                                <Button onClick={handleClear} style={{ backgroundColor: '#fb923c', color: 'white' }}  color="warning" className="position-absolute bottom-0 end-0 mb-4">Limpar campos</Button>
                                <Button onClick={handleSubmit} color="primary" className="position-absolute bottom-0 end-0 right-0 mr-5 mb-4">Salvar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>  

            {/* editar processo */}
            <ProtectedRoute permissionsAccepted={['change_subsubject']} mode={'component'}>
                <Drawer backdrop="static" open={openEdit} onClose={handleEditModalClose}
                    size={(window.innerWidth < 768) ? 'full' : 'sm'}
                >
                    <Drawer.Header className="pt-4">
                        <h4>Edição de Subassunto</h4>
                    </Drawer.Header>
                    <Drawer.Body>
                            <Row className="mb-4">
                                <h4>Nome</h4>
                                <Input placeholder="Digite uma observação" id="input-name" value={name} onChange={setName}></Input>
                            </Row>
                            <Row className="mb-4">
                                <h4>Assunto</h4>
                                <Select id="select-assunto" value={subject} onChange={setSubject} data={subjectList} placeholder="Selecione um Assunto" style={{ width: "100%" }} size="lg">
                                    
                                </Select>
                            </Row>
                            <Row className="mb-4">
                                <h4>Descrição</h4>
                                <Input placeholder="Digite uma descrição" as="textarea" rows={10} id="input-description" value={description} onChange={setDescription}></Input>
                            </Row>
                            <Row>
                                <Button onClick={handleUpdate} color="primary" className="position-absolute bottom-0 end-0 right-0 mr-5 mb-4">Atualizar</Button>
                            </Row>
                    </Drawer.Body>
                </Drawer>
            </ProtectedRoute>

            <ProtectedRoute permissionsAccepted={['delete_subsubject']} mode={'component'}>
                <Modal open={modalOpen} onClose={handleModalClose}>
                    <Modal.Header>
                    <Modal.Title>Deletar Subassunto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Deseja realmente deletar o Subassunto? Essa ação é irreversível.
                    </Modal.Body>
                    <Modal.Footer>
                    <Button size="sm" onClick={handleModalClose} appearance="subtle">
                        Cancelar
                    </Button>
                    <Button size="sm" style={{ backgroundColor: '#f87171' }} onClick={handleDelete} appearance="primary">
                        Confirmar
                    </Button>
                    </Modal.Footer>
                </Modal>
            </ProtectedRoute>
        </Container>
        </>
    )
}