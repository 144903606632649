/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { getRolesDescript } from "auth/jwtUtils";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { destroySession } from "auth/protectedRoute";
import Submenu from "../Utils/Submenu";

var ps;

const Sidebar = (props) => {
  const { collapsed, setCollapsed, collapseActive, setCollapseActive } = props;

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => {
      if (mediaQuery.matches) {
        setCollapsed(false);
        setCollapseActive(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  // Submenu state
  const [submenuCollapseActive, setSubmenuCollapseActive] = useState(true);
  const [submenuCollapsed, setSubmenuCollapsed] = useState(true);
  const toggleSubmenu = () => {
    setSubmenuCollapsed(!submenuCollapsed);
    setSubmenuCollapseActive(!submenuCollapseActive);
  }

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    setCollapseActive(!collapseActive);
    if (!submenuCollapsed) setSubmenuCollapsed(true);
  };

  const openSidebar = () => {
    setCollapsed(false);
  }

  const closeSidebar = () => {
    setCollapsed(true);
  }

  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    const roles = getRolesDescript();
    let temporaryRoutes = routes.filter((route) => {
      if (route.permissions.length > 0) {
        return route.permissions.some((r) => roles.includes(r));
      } else {
        return true;
      }
    }
    );

    return temporaryRoutes.map((prop, key) => {
      return (
        prop.submenu ?
        (
          <Submenu
            prop={prop}
            key={key}
            closeCollapse={closeCollapse}
            openSidebar={openSidebar}
            closeSidebar={closeSidebar}
            sidebarCollapseActive={collapseActive}
            submenuCollapsed={submenuCollapsed}
            setSubmenuCollapsed={setSubmenuCollapsed}
            toggleSubmenu={toggleSubmenu}
            submenuCollapseActive={submenuCollapseActive}
          />
        ) :
        (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              onMouseOver={() => {
                return collapseActive ? openSidebar() : null
              }}
              onMouseOut={() => {
                return collapseActive ? closeSidebar() : null
              }}
            >
              <i className={prop.icon} />
              <span className="nav-link-text">{prop.name}</span>
            </NavLink>
          </NavItem>
        )
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className={`navbar-vertical fixed-left navbar-light bg-white ${collapsed ? 'collapsed' : ''}`}
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <div className="d-flex align-items-center">
            {/* Sidebar toggler */}
            <button
              className="sidebar-toggler"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-icon" />
            </button>

            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          </div>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle bg-white">
                  <img
                    alt="..."
                    src={require("assets/img/theme/1144760.jpeg")}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Bem vindo!</h6>
              </DropdownItem>
              <DropdownItem to="/perfil" tag={Link}>
                  <i className="fa-solid fa-user" />
                  <span>Perfil</span>
              </DropdownItem>
              <DropdownItem>
                <i className="fa-solid fa-circle-info" />
                <span>Suporte</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => {destroySession()}}>
                <i className="fa-solid fa-right-from-bracket" />
                <span>Sair</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* Divider */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
