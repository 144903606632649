import React, { useState, useEffect } from "react";
import {
    
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Label,
    Row,
} from "reactstrap";
// core components

import { getProcessList, getProcessParams, getProcessByMarker } from "services/processService.js";
import { getCategoryList } from "services/categoryService.js";
import { getUsersList } from "services/userService.js";

import { Table, Checkbox, Popover, Whisper, Tag, MaskedInput, Steps, Panel, ButtonGroup, Button, toaster, Toggle, Divider, InputPicker, Message, Modal, CheckTreePicker, Loader, CustomProvider  } from 'rsuite';
import Select from 'rsuite/SelectPicker'
import 'rsuite/Table/styles/index.css';
import { MessageBox } from "components/Utils/MessageBox";

import CheckRoundIcon from '@rsuite/icons/CheckRound';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';

import { Link, useNavigate  } from 'react-router-dom';
import { assignProcess } from "services/processService";

import { NavLink } from "react-router-dom";

import { ptBR } from "rsuite/esm/locales";

//import css
import './distribuicao.css';
import { randomAssign } from "services/processService";

const { Column, HeaderCell, Cell } = Table;

export default function Distribuicao() {


    const [isNarrow, setIsNarrow] = useState(window.innerWidth < 900);

    const [openModal, setOpenModal] = useState(false);

    const [categorySelected, setCategorySelected] = useState([]);

    const [userSelected, setUserSelected] = useState([]);

    const [data, setData] = useState([]);
    const [recoveryData, setRecoveryData] = useState([]);

    const [dataUsers, setDataUsers] = useState([]);

    const [usersToDistribute, setUsersToDistribute] = useState([{}]);

    const [loadingProcess, setLoadingProcess] = React.useState(true);

    const [checkedKeys, setCheckedKeys] = React.useState([]);

    const [categorySearch, setCategorySearch] = React.useState('');

    const [categoryList, setCategoryList] = useState([]);

    const [distributedMaker, setdistributedMaker] = useState('')
    const [classifiedMaker, setclassifiedMaker] = useState('')

    const [numProcessSearch, setNumProcessSearch] = useState('')

    const [usersToList, setUsersToList] = useState([]);

    const [toggleRandom, setToggleRandom] = useState(true);

    const [stepFinish, setStepFinish] = useState(false);

    const [loadingDistribute, setLoadingDistribute] = useState(false);

    const [sucessResponse, setSuccessResponse] = useState(false);

    // muda para estado aleatorio e reseta as escolhas

    // processo ja classificado, nucleo sem usuario, processo já distribuido, processos com núcleo alvo diferente, todos os processos com núcleo alvo diferente, usuario sem núcleo
    const [warnings, setWarnings] = useState([false, false, false, false, false]);

    //steper
    const [step, setStep] = useState(0);
    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep);
    };

    const navigate = useNavigate();

    // proxima página
    const onNext = () => {
        if(step == 0 && !checkedKeys.length == 0){
            onChange(step + 1);
        }else if(step > 0 && step < 2){
            onChange(step + 1);
        }else{
            toaster.push(MessageBox("warning", "Atenção", "Selecione pelo menos um processo."),
        4000);
        }
    };
    const onPrevious = () => onChange(step - 1);

    const status = [
        { label: 'Classificado', value: 1 },
        { label: 'Sem classificação', value: 0 }
    ];

    const statusDistribute = [
        { label: 'Distribuído', value: 1 },
        { label: 'Não distribuído', value: 0 }
    ];

    //inicializa os processos
    useEffect(() => {
        setData([]);
        setLoadingProcess(true);
        getProcessByMarker().then((response) => {
            let newData = response.data.map((item) => {

                const classificationStatus = item.classified ? <Tag color="green">Classificado</Tag> : <Tag>Sem classificação</Tag>;

                const distributionStatus = item.distributed ? <Tag color="green">Distribuído</Tag> : <Tag>Não distribuído</Tag>;

                return {
                    id: item.id,
                    number: <Link to={`/gerenciar/processo/${item.number}`}>{item.number}</Link>,
                    number_int: item.number,
                    category: item.category,
                    category_id: item.category_id,
                    distributed: item.distributed,
                    classified: item.classified,
                    status: <div>{classificationStatus} {distributionStatus}</div>
                }
            });

            setData(newData);
            setRecoveryData(newData);
            setLoadingProcess(false);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    // função que busca os processos -- tela 1
    function searchParams(){

        setLoadingProcess(true);

        if(numProcessSearch == "" && categorySearch == "" && distributedMaker === "" && classifiedMaker === ""){
            
            setData(recoveryData);
            setLoadingProcess(false);
            return
        }

        setData(recoveryData.filter((item) => {
            if(numProcessSearch != "")
                if(item.number_int != numProcessSearch)
                    return false
            if(categorySearch != "")
                if(item.category_id != categorySearch)
                    return false
            if(distributedMaker !== "")
                if(item.distributed != distributedMaker)
                    return false
            if(classifiedMaker !== "")
                if(item.classified != classifiedMaker)
                    return false
            return true
            
        }));

        setLoadingProcess(false);
    }

    //funcao que distribui os processos
    function distributeProcess(){
        if(warnings[1] || warnings[4] || ((categorySelected == "" || categorySelected == null) &&
        (userSelected == "" || userSelected == null)) ){
            toaster.push(MessageBox("error", "Erro", "Não é possível distribuir com avisos de erro."),);
            return
        }
        setOpenModal(true);
    }

    function confirmDistribute(){
        // call distribute screen
        onNext();

        setOpenModal(false);

        setLoadingDistribute(true);
        setTimeout(() => {

            if(toggleRandom){
                randomAssign(categorySelected, checkedKeys).then((response) => {
                    console.log(response)
                    if(response.status === 200){
                        setLoadingDistribute(false);
                        setSuccessResponse(true);
                    }else{
                        setLoadingDistribute(false);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }else{
                assignProcess(userSelected, checkedKeys).then((response) => {
                    console.log(response)
                    if(response.status === 200){
                        setLoadingDistribute(false);
                        setSuccessResponse(true);
                    }else{
                        setLoadingDistribute(false);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
            setStepFinish('finish');

        }, (Math.random() * (2000 - 1000)) + 1000);

        

    }

    //reseta apos ter botao trocado
    useEffect(() => {
        setWarnings([warnings[0], false, false, false, false, false])
        setCategorySelected([])
        setUserSelected([])
    }, [toggleRandom])

    //category list 
    useEffect(() => {
        setCategoryList([]);
        var listCategory = []
        getCategoryList().then((response) => {
            setCategoryList(response.data.results.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
             }
            ));
        }).catch((error) => {
            console.log(error);
        })

        },[])

    //get users
    useEffect(() => {
        setDataUsers([]);
        setUsersToList([]);

        getUsersList().then((response) => {

            // response.data.results.filter((user) => {
            //     return user.is_active == true
            // }).map((item) => {

            //     setDataUsers(dataUsers => [...dataUsers, {
            //         id: item.id,
            //         category: item.profile?.category
            //     }])

            //     setUsersToList(usersToList => [...usersToList, {
            //         label: item.username,
            //         value: item.id,
            //     }])

            // });
            setDataUsers(response.data.results.filter((user) => {
                return user.is_active == true
            }).map((item) => {
                return {
                    id: item.id,
                    category: item.profile?.category
                }
            }))

            setUsersToList(response.data.results.filter((user) => {
                return user.is_active == true
            }).map((item) => {
                return {
                    label: item.username,
                    value: item.id,
                }
            }))

        }).catch((error) => {
            console.log(error);
        });
    }, []);

    // renderiza icone do item
    const renderMenuItem = (label, item) => {
        return (
          <div>
            <i class="fa-solid fa-user mr-2"></i>
            {label}
          </div>
        );
      };

    // renderiza icone do item de categoria
    const renderMenuItemCategory = (label, item) => {
        return (
            <div>
                <i class="fa-solid fa-user-group mr-2"></i>
                {label}
            </div>
        );
    };


    // funcao que verifica se algum processo selecionado ja foi classificado
    useEffect(() => {
        let tempObj = warnings
        tempObj[0] = false
        setWarnings(tempObj)
        checkedKeys.map((item) => {
            data.map((process) => {
                if(process.id == item)
                    // sai da funcao se encontrar um processo classificado
                    if(process.statusCode == 1){
                        tempObj[0] = true
                        setWarnings(tempObj)
                        return
                    }
            })
        })
    }, [checkedKeys])

    // funcao que seleciona os usuarios para distribuir no núcleo, senão seleciona o usuario unico
    useEffect(() => {
        let tempObj = warnings

        setUsersToDistribute([])

        if(toggleRandom && categorySelected != "" && categorySelected != null){
            tempObj[1] = true
            setWarnings(tempObj)
            dataUsers.map((item) => {
                if(item.category == categorySelected){
                    setUsersToDistribute(usersToDistribute => [...usersToDistribute, item])
                    tempObj[1] = false
                    setWarnings(tempObj)
                }
            })
        }else{
            dataUsers.map((item) => {
                if(item.id == userSelected){
                    setUsersToDistribute(userSelected)
                    return 
                }
            })
        }
    },[categorySelected, toggleRandom, userSelected, checkedKeys]);

    // funcao que verifica se algum processo tem nucleo alvo diferente, ou se todos são diferentes
    useEffect(() => {

        if((categorySelected == "" || categorySelected == null) && (userSelected == "" || userSelected == null)){
            return
        }

        let tempObj = warnings
        tempObj[3] = false
        tempObj[4] = true
        setWarnings(tempObj)
        checkedKeys.map((item) => {
            data.map((process) => {
                if(process.id == item)
                    if(toggleRandom){
                        if(process.category_id == categorySelected){
                            tempObj[4] = false
                            setWarnings(tempObj)
                        }else{
                            tempObj[3] = true
                            setWarnings(tempObj)
                        }
                    }else{
                        if(process.category_id == dataUsers.find((item) => item.id == userSelected).category){
                            tempObj[4] = false
                            setWarnings(tempObj)
                        }else{
                            tempObj[3] = true
                            setWarnings(tempObj)
                        }
                    }
            })
        })
    }, [categorySelected, checkedKeys, userSelected])

    let checked = false;

    let indeterminate = false;

    if (checkedKeys.length === data.length) {
        checked = true;
    } else if (checkedKeys.length === 0) {
        checked = false;
    } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
    }

    const handleCheckAll = (value, checked) => {
        const keys = checked ? data.map(item => item.id) : [];
        setCheckedKeys(keys);
    };

    const handleCheck = (value, checked) => {
        setCheckedKeys((prevKeys) => {
            return checked ? [...prevKeys, value] : prevKeys.filter(item => item !== value);
        });
    };

    // const NameCell = ({ rowData, dataKey, ...props }) => {
    //     const speaker = (
    //       <Popover title="Dados adicionais">
    //         <p>
    //           <b style={{ fontSize: 14 }} >Assunto:</b> {rowData.subject}
    //         </p>
    //         <p>
    //           <b style={{ fontSize: 14 }}>SubAssunto:</b> {rowData.subsubject}
    //         </p>
    //         <p>
    //           <b style={{ fontSize: 14 }}>Responsável:</b> {rowData.to}
    //         </p>
    //         <p>
    //           <b style={{ fontSize: 14 }}>Última movimentação:</b> {rowData.dateMov}
    //         </p>
    //       </Popover>
    //     );
      
    //     return (
    //       <Cell {...props}>
    //         <Whisper placement="top" speaker={speaker}>
    //           <a>{rowData[dataKey]}</a>
    //         </Whisper>
    //       </Cell>
    //     );
    // };

    const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
        <Cell {...props} style={{ padding: 0 }}>
          <div style={{ lineHeight: '46px' }}>
            <Checkbox
              value={rowData[dataKey]}
              inline
              onChange={onChange}
              checked={checkedKeys.includes(rowData[dataKey])}
            />
          </div>
        </Cell>
    );

    // limpa pesquisa
    const handleClear = () => {
        setNumProcessSearch('');
        setCategorySearch('');
        setdistributedMaker('');
        setclassifiedMaker('');
    }

    // controla o tamanho da tela
    useEffect(() => {
        const handleResize = () => {
          setIsNarrow(window.innerWidth < 900);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);


    return (
        <>
            <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
                <Container fluid>
                </Container>
            </div>

            
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <div style={{ margin: "30px 30px 10px 40px", paddingTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Steps current={step} style={{ width: '80%' }} currentStatus={stepFinish}>
                                    <Steps.Item title="Selecionar processos">
                                    </Steps.Item>
                                    <Steps.Item title="Selecionar destino ">
                                    </Steps.Item>
                                    <Steps.Item title="Distribuir">
                                    </Steps.Item>
                                </Steps>

                                <ButtonGroup style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", width: '80%' }}>
                                    <Button onClick={onPrevious} disabled={step === 0 || step === 2}>
                                    Anterior
                                    </Button>
                                    <Button onClick={step < 1 ? onNext : distributeProcess} disabled={step === 2}>
                                    Próximo
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <hr />
                            <Panel>
                                {
                                    step == 0 && 
                                    <>
                                    <CardHeader className="bg-transparent" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ width: '90%' }}>
                                            <Row className="align-items-right">
                                                <div className="col-3">
                                                    <Label style={{ fontSize: 12 }}>Nº do processo</Label>
                                                    <MaskedInput placeholder="Digite o Nº do processo" value={numProcessSearch} onChange={setNumProcessSearch}  mask={[/\d/,/\d/,/\d/,/\d/,/\d/,/\d/, /\d/, '-', /\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/,'.',/\d/,'.',/\d/,/\d/,'.',/\d/,/\d/,/\d/,/\d/]}></MaskedInput>
                                                </div>
                                                <div className="col-3">
                                                <Label>Núcleo</Label><Select value={categorySearch} onChange={setCategorySearch} size="md" className="w-100" placeholder="Selecione um núcleo" data={categoryList} onClean={() => {setCategorySearch('')}}></Select>
                                                </div>

                                                <div className="col-3">
                                                    <Label>Classificação</Label>
                                                    <Select size="md" value={classifiedMaker} onChange={setclassifiedMaker} onClean={() => {setclassifiedMaker('')}} className="w-100" placeholder="Selecione um status" data={status}></Select>
                                                </div>    
                                                <div className="col-3">
                                                    <Label>Distribuição</Label>
                                                    <Select size="md" value={distributedMaker} onChange={setdistributedMaker} onClean={() => {setdistributedMaker('')}} className="w-100" placeholder="Selecione um status" data={statusDistribute}></Select>
                                                </div>
                                            </Row>
                                            <div className="d-flex justify-content-end mt-3">
                                                <Button color="warning" size="md" onClick={handleClear}><i class="fa-solid fa-trash mr-2" ></i>Limpar</Button>
                                                <Button style={{ marginLeft: '20px'}} color="primary" size="md" onClick={searchParams}><i class="fa-solid fa-magnifying-glass mr-2" ></i>Pesquisar</Button>
                                            </div>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <CustomProvider locale={ptBR}>
                                            <Table loading={loadingProcess} virtualized height={600}  style={{ borderRadius: 5, width: "100%" }} data={data} id="table">
                                            <Column align="center">
                                                <HeaderCell style={{ padding: 0 }}>
                                                <div style={{ lineHeight: '40px' }}>
                                                    <Checkbox
                                                    inline
                                                    checked={checked}
                                                    indeterminate={indeterminate}
                                                    onChange={handleCheckAll}
                                                    />
                                                </div>
                                                </HeaderCell>
                                                <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
                                            </Column>

                                            <Column flexGrow>
                                                <HeaderCell>Número</HeaderCell>
                                                <Cell dataKey="number" />
                                            </Column>

                                            <Column flexGrow>
                                                <HeaderCell>Núcleo</HeaderCell>
                                                <Cell dataKey="category" />
                                            </Column>

                                            <Column flexGrow>
                                                <HeaderCell>Status</HeaderCell>
                                                <Cell dataKey="status" />
                                            </Column>

                                            </Table>
                                        </CustomProvider>
                                    </CardBody>
                                    </>
                                }

                                {
                                    step == 1 &&

                                    <>

                                        <CardBody>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                                                <div style={{ display: 'flex' }} className="responsive-container">
                                                    <div className="nucleo-section">
                                                        <div>
                                                            <Label style={{ width: "90%", fontSize: 14 }}>Núcleo</Label>
                                                            <Select size="md" style={{ width: '90%', marginLeft: 10 }}
                                                            onClean={() => {
                                                                let newWarning = warnings;
                                                                newWarning[1] = false
                                                                setWarnings(newWarning)}}
                                                            
                                                                disabled={!toggleRandom} value={categorySelected} onChange={(value) => {setCategorySelected(value)}} data={categoryList} 
                                                                renderMenuItem={renderMenuItemCategory} placeholder="Selecione um núcleo"/>
                                                        </div> 

                                                        <div style={{ marginTop: 20 }}>
                                                            <Label style={{ width: "90%", fontSize: 14 }}>Usuários</Label>
                                                            <Select size="md" style={{ width: "90%", marginLeft: 10 }} disabled={toggleRandom} value={userSelected} 
                                                            onChange={(value) => {setUserSelected(value)}} data={usersToList} placeholder="Selecione um assessor"
                                                            renderMenuItem={renderMenuItem}/>
                                                        </div>

                                                        <div style={{ display: 'flex', alignItems: 'flex-end',flexDirection: 'column', marginTop: 20, marginRight: 40 }}>
                                                            <Label style={{ fontSize: 14 }}>Aleatório</Label>
                                                            <Toggle size="md" checked={toggleRandom} onChange={setToggleRandom} checkedChildren="Sim" unCheckedChildren="Não" defaultChecked/>
                                                        </div>

                                                    </div>
                                                    

                                                    <Divider vertical={isNarrow ? false : true} style={isNarrow ? { width: '100%' } : { height: 250 }} />
                                                    
                                                    <Panel header="Avisos" className="nucleo-section" bordered>
                                                        {
                                                            !warnings.some((item) => item == true) && 
                                                            ((categorySelected != "" && categorySelected != null) ||
                                                            (userSelected != "" && userSelected != null)) &&
                                                            <Message showIcon type="info">
                                                                Não há pendências
                                                            </Message>
                                                        }

                                                        {warnings[0] && <Message showIcon type="warning">
                                                            <strong>Atenção!</strong> Há processos já classificados. Estes serão redistribuídos.
                                                        </Message>}

                                                        {
                                                            (categorySelected == "" || categorySelected == null) && toggleRandom && <Message showIcon type="error">
                                                                <strong>Erro!</strong> Selecione um núcleo.
                                                            </Message>
                                                        }

                                                        {
                                                            (userSelected == "" || userSelected == null) && !toggleRandom && <Message showIcon type="error">
                                                                <strong>Erro!</strong> Selecione um usuário.
                                                            </Message>
                                                        }

                                                        {
                                                            warnings[1] && categorySelected != "" && <Message showIcon type="error">
                                                                <strong>Erro!</strong> O núcleo selecionado não possui usuários.
                                                            </Message>
                                                        }

                                                        {
                                                            warnings[2] && <Message showIcon type="warning">
                                                                <strong>Atenção!</strong> Há processos já distribuídos. Ao continuar eles serão redistribuídos.
                                                            </Message>
                                                        }

                                                        {
                                                            warnings[3] && !warnings[4] && <Message showIcon type="warning">
                                                                <strong>Atenção!</strong> Há processos com núcleo alvo diferente. Estes processos serão ignorados.
                                                            </Message>
                                                        }

                                                        {
                                                            warnings[4] && ((categorySelected != "" && categorySelected != null) || (userSelected != "" && userSelected != null)) && <Message showIcon type="error">
                                                                <strong>Erro!</strong> Todos os processos possuem núcleo alvo diferente. Nenhum processo será distribuído.
                                                            </Message>
                                                        }


                                                    </Panel>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </>
                                }
                                {
                                    step >= 2 &&
                                    <div>


                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '250px'}}>

                                            {loadingDistribute && <Loader size="lg" content="Distribuindo processos..." vertical />}

                                            {!loadingDistribute && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    {sucessResponse && <><CheckRoundIcon style={{ fontSize: 20, color: '#4caf50', marginLeft: 20, marginRight: 10 }} />
                                                    <strong>Processos distribuídos com sucesso!</strong></>}

                                                    {
                                                        !sucessResponse && <><CloseOutlineIcon style={{ fontSize: 20, color: '#ed4337', marginLeft: 20, marginRight: 10 }} />
                                                        <strong>Erro ao distribuir processos!</strong></>
                                                    }
                                                </div>
                                                <div style={{ marginTop: 40 }}>
                                                    <Button style={{ marginLeft: 20 }} onClick={() => window.location.reload()}>Nova distribuição</Button>
                                                    <Button style={{ marginLeft: 20 }} onClick={() => navigate('/gerenciar/processo/')} >Lista de processos</Button>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                }
                            </Panel>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal backdrop={'static'} role="alertdialog" keyboard={false} open={openModal} onClose={() => {setOpenModal(false)}}>
                <Modal.Header>
                    <Modal.Title>Distribuição</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <span>
                        Ao prosseguir, os processos serão distribuídos para os usuários do núcleo selecionado.
                    </span>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={confirmDistribute} appearance="primary">
                    Ok
                </Button>
                <Button onClick={() => setOpenModal(false)} appearance="subtle">
                    Cancelar
                </Button>
                </Modal.Footer>
            </Modal>
           
        </>
    )
}