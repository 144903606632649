import axios from "axios";
import { getTokenDecript } from "../auth/jwtUtils";

const BASEURL = process.env.REACT_APP_BASE_URL;
const PROCESS_URL = process.env.REACT_APP_ENDPOINT_PROCESS
const TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export async function getProcessList(page, size, bool) {
  //mudança
  var finalquery;
  if(bool){
    finalquery = '?page='+page+'&page_size='+size
  }else{
    finalquery = ''
  }

  try {
    const response = await axios.get(BASEURL+PROCESS_URL+finalquery, 
      {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }, timeout: 50000
      })
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function saveProcess(process){
  const transformedJson = JSON.stringify(process)
  try {
    const response = await axios.post(BASEURL+PROCESS_URL, transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }
  
  })
    if(response.status === 201) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function deleteProcess(id){
  try {
    const response = await axios.delete(BASEURL+PROCESS_URL+id, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 204) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getProcessParams(number, category, subject, subsubject, userid, obs, page, size, bool){
    if(category === undefined || category === null){
      category = ""
    }else if(subject === undefined || subject === null){
      subject = ""
    }else if(subsubject === undefined || subsubject === null){
      subsubject = ""
    }

    var finalquery;
    if(bool){
      finalquery = '&page='+page+'&page_size='+size
    }else{
      finalquery = '';
    }
    try {
      const response = await axios.get(BASEURL+PROCESS_URL+'?number='+number+'&category='+category+'&subject='+subject+'&subsubject='+subsubject+'&classifier_user='+userid+'&obs='+obs+finalquery, 
        {
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getTokenDecript(),
        'Application-Token': TOKEN,
      }})
      if(response.status === 200) {
        return response;
      }
      console.log(response)
    }catch(error) {
      console.log(error)
      return error
    }
}

export async function cadastrarProcessoLote(listProcessos, nucleo){
  try {
    const sucessAdd = [];

    const promises = listProcessos.map(async (processo) => {
      const jsonSubject = {
        number: processo,
        category_id: nucleo,
      };

      try {
        const response = await axios.post(BASEURL + PROCESS_URL, JSON.stringify(jsonSubject), {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getTokenDecript(),
            'Application-Token': TOKEN,
          }
        });

        if (response.status === 201) {
          sucessAdd.push(response.data);
        } else {
        }
      } catch (error) {
        console.error('Erro ao enviar dados:');
      }
    });

    await Promise.all(promises);

    return sucessAdd;
  } catch (error) {
    console.log("caught error outside promise loop:", error);
  }
}

export async function updateProcess(id, data){
  const transformedJson = JSON.stringify(data)
  try {
    const response = await axios.put(BASEURL+PROCESS_URL+id+'/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function assignProcess(userId, listProcessIds){
  const transformedJson = JSON.stringify(
    {
      "user_id": userId,
      "processes_ids": listProcessIds
    })
  try {
    const response = await axios.post(BASEURL+PROCESS_URL+'assign/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function randomAssign(categoryId, processes_ids){
  const transformedJson = JSON.stringify(
    {
      "category_id": categoryId,
      "processes_ids": processes_ids
    })

  try {
    const response = await axios.post(BASEURL+PROCESS_URL+'random_assign/', transformedJson, {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getProcessesInfo(){
  try {
    const response = await axios.get(BASEURL+PROCESS_URL+'infos_process/', {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}

export async function getProcessByMarker(){

  try {
    const response = await axios.get(BASEURL+PROCESS_URL+'process_by_marker/', {
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+getTokenDecript(),
      'Application-Token': TOKEN,
    }})
    if(response.status === 200) {
      return response;
    }
    console.log(response)
  }catch(error) {
    console.log(error)
    return error
  }
}