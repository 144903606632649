import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';


const ViewerDocument = ({ documents, height }) => {
    
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <TabView activeIndex={activeIndex} style={{ 
            ul: {
                MarginTop: '0px',
                MarginBottom: '0px',
            },
            
        }}>
            {documents.forEach(i => {
                return (
                    <TabPanel key={i.description} header={i.description} 
                        // closable={index == 0 ? false : true} 
                        // onEvent={(e) => {
                        //     if (activeIndex === index) {
                        //         setActiveIndex(activeIndex - 1);
                        //     }
                            
                        // }}
                    >
                        <iframe
                            title={i.description}
                            src={'data:' + i.mimetype + ';base64,' + i.content}
                            width="100%"
                            height={height}
                            className='d-flex justify-content-center'
                            allowFullScreen
                        />
                    </TabPanel>
                );
            })}
        </TabView>
    );
};

export default ViewerDocument;