// react plugin used to create charts
import { Bar, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { CustomLoadingOverlay, LostConnectionOverlay } from "../components/Utils/nodata";
import React, { useEffect, useState } from "react";
// core components
import {
  chartExample1,
  chartExample2,
  stackedBar,
  chartOptions,
  parseOptions,
} from "variables/charts.js";

// javascipt plugin for creating charts
import Chart from "chart.js";
import Header from "components/Headers/Header.js";
/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// node.js library that concatenates classes (strings)
import classnames from "classnames";
import ProtectedRoute from "auth/protectedRoute";
import { getProcessesInfo } from "services/processService";

const Index = (props) => {


  const [activeNav, setActiveNav] = useState(0);
  const [chartExample1Data, setChartExample1Data] = useState(["assunto", "subassunto", "nucleo"]);

  // novos states
  const [infoProcessHeader, setInfoProcessHeader] = useState({});
  
  const [keyChild, setKeyChild] = useState(0);
  const [pieAssunto, setPieAssunto] = useState({});
  const [pieSubAssunto, setPieSubAssunto] = useState({});
  const [pieNucleo, setPieNucleo] = useState({});
  const [isLostConnection, setIsLostConnection] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [chartBarGroupbyUser, setchartBarGroupbyUser] = useState([])
  const [chartBarUser, setchartBarUser] = useState([])

  const palette = [
    "#0B3D91",
    "#2E2E2E",
    "#F4F4F4",
    "#6D001A",
    "#5A769A",
    "#1C1C1C",
    "#9E9E9E",
    "#2C5364",
    "#EFEFEF",
    "#4B0082"
  ]

  function generatePieChart(nucleo, assunto, subassunto){

      const nucleoModel = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: palette
          },
        ],
      }

      const assuntoModel = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: palette
          },
        ],
      }

      const subassuntoModel = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: palette
          },
        ],
      }

      nucleo.forEach(item => {
        nucleoModel.labels.push(item.category__name)
        nucleoModel.datasets[0].data.push(item.total)
      })

      assunto.forEach(item => {
        assuntoModel.labels.push(item.subject__name)
        assuntoModel.datasets[0].data.push(item.total)
      })

      subassunto.forEach(item => {
        subassuntoModel.labels.push(item.subsubject__name)
        subassuntoModel.datasets[0].data.push(item.total)
      })


      setPieNucleo(
        nucleoModel
      );

      setPieAssunto(
        assuntoModel
      );

      setPieSubAssunto(
        subassuntoModel
      );


  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setChartExample1Data("data" + index);
  };

  useEffect(() => {
    setIsLoadingData(true)
    const fetchData = async () => {
      try {
        const resultado = await getProcessesInfo();
        if(resultado.status != 200){
        }else{

          // informações necessárias para o header
          setInfoProcessHeader(
            {
              'number_process': resultado.data.number_process,
              'number_process_without_classification': resultado.data.number_process_without_classification,
              'delivery_today_to_user': resultado.data?.delivery_today_to_user,
              'classified_today_by_user': resultado.data?.classified_today_by_user,
              'last_process_created': resultado.data?.last_process_created,
              'most_user_id': resultado.data?.most_user_classifier?.classifier_user,
              'most_user_first_name': resultado.data?.most_user_classifier?.classifier_user_first_name,
              'most_user_last_name': resultado.data?.most_user_classifier?.classifier_user_last_name,
            }
          )

          const processesByUser = {
            labels: [],
            datasets: [
              {
                label: "Classificados",
                data: [],
              },
              {
                label: "Sem classificação",
                data: [],
              },
            ],
          }

          if(resultado.data?.classified_or_not_by_user != null){
            resultado.data?.classified_or_not_by_user.forEach(item => {
              processesByUser.labels.push(item.classifier_user == null ? 'Sem classificador' : item.classifier_user_first_name + ' ' + item.classifier_user_last_name)
              processesByUser.datasets[0].data.push(item.classified)
              processesByUser.datasets[1].data.push(item.not_classified)
            })
          }

          setchartBarGroupbyUser(processesByUser)

          let distributed_classified_user = resultado.data?.distributed_and_classified_by_user_per_month

          if(distributed_classified_user != null){

            let distributed = Array(12).fill(0)
            let classified = Array(12).fill(0)
            distributed_classified_user.forEach(item => {
              distributed[item.distribution_date__month-1] = item.distributed
              classified[item.distribution_date__month-1] = item.classified
            })

            setchartBarUser({
              "distributed": distributed,
              "classified": classified
            })
  
          }

          let category_subject_subsubject = resultado.data?.number_process_by_category_subject_subsubject;

          generatePieChart(category_subject_subsubject.category,category_subject_subsubject.subject, category_subject_subsubject.subsubject)

          setIsLoadingData(false)
        } 
        
      } catch (error) {
        console.error('Erro ao buscar dados assíncronos:', error);
      }
    }

    fetchData();
  }, []); 


  return (
    <>
      <Header key={keyChild} processesInfo={infoProcessHeader} lostConnection={isLostConnection}/>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow" >
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Processos
                    </h6>
                    <h2 className="mb-0">{new Date().getFullYear()}</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                {!isLoadingData ? (
                  isLostConnection ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                      {LostConnectionOverlay()}
                    </div>
                  ) : (
                    <>
                      <ProtectedRoute permissionsAccepted={['view_process']} mode={'component'}>
                        <Bar data={{
                          labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                          datasets: [
                            {
                              label: "Distribuídos",
                              // data: groupProcessByYear(processData, new Date().getFullYear()),
                              data: chartBarUser?.distributed,
                              maxBarThickness: 10,

                            },
                            {
                              label: "Classificados",
                              data: chartBarUser?.classified,
                              maxBarThickness: 10,
                              backgroundColor: '#11cdef'
                            }
                          ],
                        }} options={chartExample2.options} />
                      </ProtectedRoute>
                      <ProtectedRoute permissionsAccepted={['view_all_process']} mode={'component'}>
                        <Bar data={
                          {
                            labels: chartBarGroupbyUser.labels,
                            datasets: [
                              {
                                label: "Sem classificação",
                                data: chartBarGroupbyUser.datasets[1].data,
                                maxBarThickness: 10,
                              },
                              {
                                label: "Classificados",
                                data: chartBarGroupbyUser.datasets[0].data,
                                maxBarThickness: 10,
                                backgroundColor: '#11cdef'
                              },
                            ],
                          }
                        } options={stackedBar.options} />
                      </ProtectedRoute>
                    </>
                  )
                ) : <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                  {CustomLoadingOverlay()}
                </div>}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="mb-5 mb-xl-0" xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  
                  <div className="col">
                    
                    <Nav className="d-flex justify-content-around" pills>
                      
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-2")}
                          onClick={() => {
                            setActiveNav(activeNav == 0 ? chartExample1Data.length - 1 : activeNav - 1)
                          }}
                        >
                          <span className="d-none d-md-block"><i className="fas fa-arrow-left" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                          <span className="d-md-none"><i className="fas fa-arrow-left" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                        </NavLink>
                      </NavItem>
                      <div className="d-flex justify-content-center flex-column">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                          Processos por
                        </h6>
                        <h2 className="mb-0">{chartExample1Data[activeNav]}</h2>
                      </div>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-2")}
                          data-toggle="tab"
                          onClick={() => {
                            setActiveNav(activeNav == (chartExample1Data.length -1) ? 0 : activeNav + 1)
                          }}
                        >
                          <span className="d-none d-md-block"><i className="fas fa-arrow-right" /> {activeNav == chartExample1Data.length-1 ?  chartExample1Data[0] : chartExample1Data[activeNav+1]}</span>
                          <span className="d-md-none"><i className="fas fa-arrow-right" /> {activeNav == 0 ? chartExample1Data[chartExample1Data.length-1] : chartExample1Data[activeNav-1]}</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">

                {!isLoadingData ? (
                  isLostConnection ? (
                    <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                      {LostConnectionOverlay()}
                    </div>
                  ) : (
                    <Pie
                    data={chartExample1Data[activeNav] == 'assunto' ? pieAssunto : (
                      chartExample1Data[activeNav] == 'subassunto' ?
                      pieSubAssunto : pieNucleo)}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                  )
                ) : <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                  {CustomLoadingOverlay()}
                </div>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;