// reactstrap components
import {
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";

import './AdminNavBar.css'

/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
import { destroySession } from "auth/protectedRoute";
import { getInfosDecript } from "auth/jwtUtils";

import NoticeIcon from '@rsuite/icons/Notice';
import { Button, Badge  } from 'rsuite';

import React, { useState } from "react";

import { hasRole } from "auth/jwtUtils";

import { getRequests } from "services/requestService";
import { getAdditionalInfoDescript } from "auth/jwtUtils";

const AdminNavbar = (props) => {

  const [notificationNumber, setNotificationNumber] = useState(0);

  React.useEffect(() => {

    
    async function fetchData() {
      if (hasRole('change_changecategoryrequest')) {
        let response = await getRequests("", "", "", "", "", '');
        if (response?.status === 200) {
          setNotificationNumber(response.data.results.filter((request) => request?.status === 'PENDENTE').length);
        } else {
          setNotificationNumber(0);
        }
      } else {
        let response = await getRequests("", "", getAdditionalInfoDescript().id, "", "", '');
        if (response?.status === 200) {
          setNotificationNumber(response.data.results.filter((request) => request?.status != 'PENDENTE' && !request?.seen).length);
        } else {
          setNotificationNumber(0);
        }
      }
    }
    fetchData();

    // Executa fetchData a cada 3 segundos
    const intervalId = setInterval(fetchData, 2000);
  
    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  
  }, []);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <NavLink
            className="h4 mb-0 text-white text-uppercase d-lg-inline-block responsive-style"
            to={'/'+props?.brandText}
          >
            {props.brandText}
          </NavLink>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
         

          <Nav className="align-items-center d-none d-flex" navbar>

          <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>

                    {
                      notificationNumber == 0 ? 
                      <Media className="align-items-center">
                        <Media className="ml-2 d-lg-block responsive-style">
                          <span className="mb-0 text-sm font-weight-bold">
                            <Button style={{ backgroundColor: 'transparent' }}>
                                <NoticeIcon color="white" />
                            </Button>
                          </span>
                        </Media>
                      </Media> : 
                      <Badge content={ notificationNumber } className="responsive-style">
                      <Media className="align-items-center">
                        <Media className="ml-2 d-lg-block responsive-style">
                          <span className="mb-0 text-sm font-weight-bold">
                          <Badge content={ notificationNumber} className="responsive-style">
                            <Button style={{ backgroundColor: 'transparent' }}>
                                <NoticeIcon color="white" />
                            </Button>
                          </Badge>
                          </span>
                        </Media>
                      </Media>
                    </Badge>}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Caixa de entrada</h6>
                </DropdownItem>
                <DropdownItem to="/notificacoes" tag={Link} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Abrir notificações</span>
                  <i class="fa-solid fa-arrow-up-right-from-square" style={{ fontSize: '12px' }}></i>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <Media className="ml-2 d-lg-block responsive-style">
                    <span className="mb-0 text-sm font-weight-bold">
                      {getInfosDecript().username}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem vindo!</h6>
                </DropdownItem>
                
                <DropdownItem to="/perfil" tag={Link}>
                    <i className="fa-solid fa-user" />
                    <span>Perfil</span>
                </DropdownItem>
                <DropdownItem to="/user-profile" tag={Link}>
                  <i className="fa-solid fa-circle-info" />
                  <span>Suporte</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="" onClick={() => {destroySession()}}>
                  <i className="fa-solid fa-right-from-bracket" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
